<template>
  <div>
    <b-navbar
      id="large-nav"
      class="hidden-sm-down"
      toggleable="lg"
      type="light"
      variant="light"
    >
      <b-navbar-brand v-b-toggle.sidebar-1>
        <font-awesome-icon icon="bars" class="icon" />
      </b-navbar-brand>
      <b-dropdown id="nav-dropdown" right text="Akun" variant="transparent">
        <b-dropdown-item href="#/settings/password"
          >Ubah Password</b-dropdown-item
        >
        <div class="dropdown-divider"></div>
        <b-dropdown-item @click.prevent="logOut">Sign Out</b-dropdown-item>
      </b-dropdown>
    </b-navbar>

    <b-container>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </b-container>
  </div>
</template>

<script>
  export default {
    
  }
</script>