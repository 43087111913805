<template>
  <div class="d-flex">
    <b-sidebar
      text-variant="light"
      bg-variant="dark"
      id="sidebar-1"
      no-header
    >
      <template>
        <div id="header-sidebar">
          <b-row>
            <b-col md="3">
              <img
                src="/assets/images/logo-aw-no-margin.png"
                alt=""
                width="50"
              />
            </b-col>
            <b-col md="9" class="pt-1">
              <p class="app-title">PUSAT DATA KEANGGOTAAN</p>
              <p class="app-content">AL JAM’IYATUL WASHLIYAH SUMATERA UTARA</p>
            </b-col>
          </b-row>
        </div>
      </template>
      <nav class="mt-3">
        <b-row class="justify-content-center mt-3 mb-3">
          <b-col md="6" class="text-center">
            <img src="/assets/images/account.png" alt="" width="100" />
            <p class="account-name">
              {{ $store.getters["account/accountData"].name }}
            </p>
            <p class="capitalize">
              {{ $store.getters["account/accountData"].role }}
            </p>
          </b-col>
        </b-row>
        <b-nav
          vertical
          type="light"
          variant="light"
          class="justify-content-center"
        >
          <b-nav-item :to="'/dashboard'"><span><font-awesome-icon icon="home" class="icon" /></span>{{$store.getters["account/accountData"].role === 'super admin' ? 'Beranda' : 'Sub Beranda'}}</b-nav-item>
          <b-nav-item :to="'/users'" v-if="$store.getters['account/accountData'].role === 'super admin'"><span><font-awesome-icon icon="user" class="icon" /></span> Kelola User</b-nav-item>
          <b-nav-item :to="'/members'"><span><font-awesome-icon icon="id-card" class="icon" /></span> Data Anggota</b-nav-item>
          <b-nav-item :to="'/members-waiting'"><span><font-awesome-icon icon="folder-open" class="icon" /></span>Daftar Tunggu Test</b-nav-item>
          <b-nav-item :to="'/members/create'"><span><font-awesome-icon icon="user-plus" class="icon" /></span>Tambah anggota</b-nav-item>
          <b-nav-item href="https://member.maribuatweb.com/buku%20induk%20anggota.pdf" target="_blank" v-if="$store.getters['account/accountData'].role === 'super admin'"><span><font-awesome-icon icon="book" class="icon" /></span>Download Buku Induk</b-nav-item>
        </b-nav>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.$swal({
        title: "Anda Yakin Akan Log Out?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        confirmButtonClass: "btn btn-sm btn-danger mr-3",
        cancelButtonClass: "btn btn-sm btn-secondary",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("account/logout")
            .then(() => {
              this.$router.push("/auth/login");
              // location.reload()
            })
            .catch(() => {
              this.$router.push("/auth/login");
              // location.reload()
            });
        }
      });
    },
  },
  mounted() {
    if (this.$webConfig.notification) {
      this.$store.getters["account/firebaseMessaging"].onMessage((payload) => {
        // payload.data.title
        // payload.data.body
        // payload.data['gcm.notification.type']
        // payload.data['gcm.notification.type_id']
        // console.log('Message received. ', payload.data['gcm.notification.type_id'])
        // 'addToCart'
        this.$store.dispatch("notification/notification", {
          title: payload.data.title,
          message: payload.data.body,
          redirect:
            "/#/orders?order_no=" + payload.data["gcm.notification.type_id"],
        });
      });
    }
  },
};
</script>